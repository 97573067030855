<template>
  <el-popover
    placement="bottom-end"
    title=""
    width="260"
    trigger="click"
    popper-class="table-column-popover"
    content="表头字段设置"
    @after-leave="afterLeave"
  >
    <div class="el-popover__title">
      自定义设置
    </div>
    <div class="el-popover__sub-title">
      可以设置常用字段，保存设置
    </div>
    <div :key="keys" class="table-column-check">
      <el-checkbox
        v-model="checkAll"
        class="table-column-all-check"
        label="全选"
        @change="changeAll"
      />
      <el-checkbox-group
        v-model="checkList"
        class="table-column-filter"
        size="mini"
        @change="changeSingle"
      >
        <Draggable
          v-model="columnsList"
          chosen-class="chosen"
          force-fallback="true"
          class="sort-body-wrap"
          animation="1000"
        >
          <TransitionGroup>
             <div
              v-for="item of columnsList"
              :key="item.prop"
              :class="[item.fixed ? 'undraggable' : '']"
              style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
            >
              <el-checkbox :label="item.prop">
                {{ item.label }}
              </el-checkbox>
              <span
                class="dargBtn"
                style="font-size: 20px; cursor: move"
              >
                <img style="width:6px;height:20px;" src="@/assets/move-sign.svg" alt="unordered">
              </span>
            </div>

          </TransitionGroup>
        </Draggable>
      </el-checkbox-group>
    </div>
    <div class="table-column-popover-footer">
       <ElButton type="text" @click="reset">
        重置
      </ElButton>

      <ElButton type="primary" @click="confirm(true)">
          确定
      </ElButton>
    </div>
    <el-tooltip
      slot="reference"
      effect="dark"
      content="表头字段设置"
      placement="top"
    >
      <el-button
        style="padding: 8px 10px;font-size:14px;"
        icon="el-icon-setting"
        plain
        size="mini"
      />
    </el-tooltip>
  </el-popover>
</template>

<script>
import { baseStorage } from '@/utils/storage.js'
import draggable from 'vuedraggable'
export default {
  components: { Draggable: draggable },
  props: {
    columns: {
      type: Array,
      default() {
        return []
      },
    },
    activeName: {
      type: [String, Number],
      default: '',
    },
    //初始设置不选中数组
    columnsInitNotSelected: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      checkList: [],
      checkAll: true,
      columnsList: [],
    }
  },
  computed: {
    key() {
      let path = this.$route.path.split('/')
      return `${path.at(-2)}_${path.at(-1)}`
    },
    keys() {
      return this.activeName || '99' 
    },
    reshColumns() {
      const {keys,columns}=this
      return {keys,columns}
    },
  },
  watch: {
    reshColumns() {
      this.init()
      this.confirm()
    },
  },
  created() {
    this.init()
    this.confirm()
  },
  methods: {
    init() {
      // 1. 获取本地存储的table字段
       const storageColumns = baseStorage.getItem(`${this.key}${this.activeName}`) || []
      // 2. 获取页面传递的table字段
      const columns = this.columns
      // 3. 判断本地存储数据，为0 代表没有存储，直接使用页面传递进来的，并全选
      if (storageColumns.length === 0) {
        this.columnsList = columns.map(item => {
          return {
            label: item.label,
            prop: item.prop,
            fixed: item.fixed,
          }
        })
        const propColumns=columns.map((item) => item.prop)
        this.checkList =propColumns.filter(item => {
          return !this.columnsInitNotSelected.includes(item)
        })
        this.checkAll = this.checkList.length===propColumns.length
      } else {
        // 4. 备份页面传递进来的 prop 数组
        const originProps = columns.map((item) => item.prop)

        // 5. 页面传递的配置遍历，进行字段的增删
        columns.forEach((item, index) => {
        // 6. 判断当前字段在本地存储是否存在
          const findIndex = storageColumns.findIndex(
            (sf) => sf.prop === item.prop,
          )
          // 7 不存在
          if (findIndex === -1) {
            // 8 判断当前字段是否是第一个字段，是第一个字段，直接插入到数组第一个位置，并默认选中（前提是初始设置需要选中）
            if (index === 0) {
              storageColumns.unshift({
                label: item.label,
                prop: item.prop,
                fixed: item.fixed,
                select: !this.columnsInitNotSelected.includes(item.prop),
              })
            } else {
              // 9 不是第一个字段，获取当前字段的前一个字段，找到前一个字段在本地存储的位置，插入到前一个字段的后面，并默认选中（前提是初始设置需要选中）
              const preItem = columns[index - 1]
              const findPreIndex = storageColumns.findIndex(
                (sf) => sf.prop === preItem.prop,
              )
              storageColumns.splice(findPreIndex + 1, 0, {
                label: item.label,
                prop: item.prop,
                fixed: item.fixed,
                select: !this.columnsInitNotSelected.includes(item.prop),
              })
            }
          }
        })

        // 10. 本地存储的配置遍历，进行字段的删
        const filterColumns =  storageColumns.filter((item) => {
          return originProps.includes(item.prop)
        })
        this.columnsList = filterColumns
        // 11. 获取选中的字段
        this.checkList = filterColumns
          .filter((item) => item.select)
          .map((item) => item.prop)

        // 12. 判断选中的字段是否和本地存储的字段一致，一致全选，不一致不全选
        this.checkAll = this.checkList.length === filterColumns.length
      }
    },
    changeAll(flag) {
      if (flag) {
        this.checkList = this.columnsList.map((item) => item.prop)
      } else {
        this.checkList = []
      }
    },
    changeSingle(rows) {
      this.checkAll = rows.length === this.columnsList.length
    },
    reset() {
      this.checkList = this.columns.filter(item =>!this.columnsInitNotSelected.includes(item.prop)).map(item => item.prop)
      this.columnsList =[...this.columns]
      this.checkAll = this.checkList.length===this.columns.length
      this.$nextTick(() => {
        this.confirm()
      })
    },
    // 13. 配置字段传递到页面显示
    confirm(flag) {
      this.$nextTick(() => {
        document.body.click()
      })
      // 14. 备份选中的字段
      const checkList = this.checkList
      // 15. 即将存储到本地的配置
      const storageColumns = this.columnsList.map((item) => {
        return {
          label: item.label,
          prop: item.prop,
          fixed: item.fixed,
          select: checkList.includes(item.prop),
        }
      })
      const originColumns = this.columns
      // 16. 获取传递到页面显示的配置
      const columns = this.columnsList.filter((item) => checkList.includes(item.prop)).map(item => {
        return originColumns.find(oc => oc.prop === item.prop)
      })
      if (flag == true) {
       baseStorage.setItem(`${this.key}${this.activeName}`, storageColumns)
        this.$emit('confirm', columns)

      } else if (this.columnsList.length > 0) {
        baseStorage.setItem(`${this.key}${this.activeName}`, storageColumns)
        this.$emit('confirm', columns)
      }
    },
    afterLeave() {
      this.init()
    },
  },
}
</script>

<style lang="scss" scoped>
.table-column-popover {
  padding: 0;
  .el-popover__title{
    margin-bottom: 0;
    margin-top: 10px;
    padding: 0 14px;
  }
  .el-popover__sub-title{
    margin-top: 4px;
    font-weight: 400;
    font-size: 12px;
    color: #929292;
    padding: 0 14px 6px;
    border-bottom: 1px solid #E3E3E3;
  }
  .table-column-check{
    max-height: 300px;
    overflow-y: auto;
  }
  .table-column-all-check {
    display: flex;
    align-items: center;
    height: 34px;
    padding: 0 1em;
    // margin-bottom: 4px;
    // border-bottom: 1px solid #dadce0;
  }
  .table-column-filter {
    display: flex;
    flex-direction: column;

    .el-checkbox {
      flex: 1;
      margin:0 10px 0 0;
      display: flex;
      align-items: center;
      height: 34px;
      padding: 0 1em;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .el-checkbox__label{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .dargBtn {
      width: 36px;
      align-self: stretch;
      vertical-align: middle;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
  .table-column-popover-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    border-top: 1px solid #dadce0;
    padding-top: 10px;
    .el-link {
      padding: 10px 16px;
      font-size: 14px;
      flex: 1;
    }
  }
}
  .item {
    display: block;
    padding: 2px 6px;
    cursor: pointer;
    color: #666;
    background-repeat: no-repeat;
    background-position: right center;
    &:last-of-type {
    margin-right: 30px!important;
     }
    ::v-deep .el-checkbox__label{
          display: inline-block;
          width:290px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          vertical-align: text-top;

    }


  }
</style>
